import { useEffect, useState } from "react";

const LogoAnimation = () => {

    const [active, setActive] = useState(true);

    useEffect(()=>{
        setTimeout(()=>{
            setActive(false);
        }, 3000)
    },[])

  return (
    <div className={`animation__outer-wrapper ${!active?"disabled":""}`}>
      <div className="animation__inner-wrapper">
        <div className="animation__frame-wrapper">
          <img
            src="/L.svg"
            alt="LONG DAY PICTURES"
            className="animation__element l"
          />
          <img
            src="/D.svg"
            alt="LONG DAY PICTURES"
            className="animation__element d"
          />
          <img
            src="/F.svg"
            alt="LONG DAY PICTURES"
            className="animation__element f"
          />
        </div>
        <div className="animation__text-wrapper">
          <img
            src="/T.svg"
            alt="LONG DAY PICTURES"
            className="animation__text"
          />
        </div>
      </div>
    </div>
  );
};

export default LogoAnimation;
