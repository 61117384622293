import { useEffect, useRef, useState } from "react";
import "./styles/global-styles.scss";
import Logo from "./components/Logo";
import LogoAnimation from "./components/LogoAnimation";

function App() {
  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
    return () => window.removeEventListener("resize", appHeight);
  }, []);

  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const neighbourVideoWrapper = useRef();
  const neighbourVideo = useRef();
  const wimVideoWrapper = useRef();
  const wimVideo = useRef();
  const miaVideoWrapper = useRef();
  const miaVideo = useRef();
  const mobileLogo = useRef();

  const handleOpenVideo = () => {
    setIsVideoOpen(true);
    const doc = document.documentElement;
    doc.style.setProperty("--text-color", `#FFF`);
  };

  const handleStopVideo = (video) => {
    video.pause();
    video.currentTime = 0;
  };

  const handleCloseVideo = () => {
    setIsVideoOpen(false);
    const doc = document.documentElement;
    doc.style.setProperty("--text-color", `#000`);

    neighbourVideoWrapper.current.classList.remove("active");
    miaVideoWrapper.current.classList.remove("active");
    wimVideoWrapper.current.classList.remove("active");
    mobileLogo.current.classList.remove("disabled");

    handleStopVideo(neighbourVideo.current);
    handleStopVideo(wimVideo.current);
    handleStopVideo(miaVideo.current);
  };

  const openNeighbourVideo = () => {
    handleOpenVideo();
    neighbourVideoWrapper.current.classList.add("active");
    mobileLogo.current.classList.add("disabled");
    neighbourVideo.current.play();
  };

  const openWimVideo = () => {
    handleOpenVideo();
    wimVideoWrapper.current.classList.add("active");
    mobileLogo.current.classList.add("disabled");
    wimVideo.current.play();
  };

  const openMiaVideo = () => {
    handleOpenVideo();
    miaVideoWrapper.current.classList.add("active");
    mobileLogo.current.classList.add("disabled");
    miaVideo.current.play();
  };

  return (
    <div className="App">
      <LogoAnimation />
      <main className="splash__wrapper">
        <div className="splash__logo-wrapper mobile" ref={mobileLogo}>
          Yesterday
          <br />& Tomorrow
        </div>
        <div className={`splash__vid-open-logo ${isVideoOpen ? "active" : ""}`}>
          Yesterday
          <br />& Tomorrow
          <button
            onClick={handleCloseVideo}
            className="splash__vid-close-button"
          >
            &times;
          </button>
        </div>
        <div className="splash__characters-wrapper">
          <div
            className="splash__video-wrapper left"
            ref={neighbourVideoWrapper}
          >
            <video
              src="https://yt.dxr.zone/WIM.mp4"
              className="splash__video"
              autoPlay={false}
              loop={true}
              ref={neighbourVideo}
            />
          </div>
          <div className="splash__video-wrapper center" ref={wimVideoWrapper}>
            <video
              src="https://yt.dxr.zone/neighbour.mp4"
              className="splash__video"
              autoPlay={false}
              loop={true}
              ref={wimVideo}
            />
          </div>
          <div className="splash__video-wrapper right" ref={miaVideoWrapper}>
            <video
              src="https://yt.dxr.zone/MIA.mp4"
              className="splash__video"
              autoPlay={false}
              loop={true}
              ref={miaVideo}
            />
          </div>

          <button
            className={`splash__item-wrapper ${isVideoOpen ? "disabled" : ""}`}
            onClick={openNeighbourVideo}
            style={{ backgroundImage: "url(wim2.png)" }}
          >
            <div className="splash__cast-name-wrapper top">
              RIVERS CHOWDHRY-CAHEE
            </div>
            <div className="splash__character-name-wrapper">Wim</div>
            <div className="splash__cast-name-wrapper bottom">
              ELLEN GRACE KOZARITS
            </div>
            <div
              className="splash__poster-wrapper"
              style={{ backgroundImage: "url(wim.png)" }}
            ></div>
          </button>

          <button
            className={`splash__item-wrapper ${isVideoOpen ? "disabled" : ""}`}
            onClick={openWimVideo}
            style={{ backgroundImage: "url(neighbour2.png)" }}
          >
            <div className="splash__logo-wrapper desktop">
              Yesterday
              <br />& Tomorrow
              <div className="splash__cast-name-wrapper super-top">
                DEEYA SAMBARI
              </div>
            </div>
            <div className="splash__character-name-wrapper">Neighbour</div>
            <div className="splash__cast-name-wrapper bottom">
              RIYA HAMID
            </div>

            <div className="splash__cast-name-wrapper top mobile">
              DEEYA SAMBARI
            </div>
            <div
              className="splash__poster-wrapper"
              style={{ backgroundImage: "url(neighbour.png)" }}
            ></div>
          </button>

          <button
            className={`splash__item-wrapper ${isVideoOpen ? "disabled" : ""}`}
            onClick={openMiaVideo}
            style={{ backgroundImage: "url(mia2.png)" }}
          >
            <div className="splash__cast-name-wrapper top desktop">
              SJOURNEE QUAIDOO
            </div>
            <div className="splash__cast-name-wrapper bottom mobile ">
            SJOURNEE QUAIDOO
            </div>
            <div className="splash__character-name-wrapper">Mia</div>
            <div
              className="splash__poster-wrapper"
              style={{ backgroundImage: "url(mia.png)" }}
            ></div>
          </button>
        </div>

        <div className="splash__footer-wrapper">
          <div className="splash__footer-small-wrapper">
            <div>
              <div className="small-text">LONG DAY PICTURES</div>
              <div className="splash__ld-logo-wrapper">
                <Logo fill={isVideoOpen ? "#FFF" : "#000"} />
              </div>
            </div>
          </div>
          <div className="splash__footer-large-wrapper">
            <div>
              “YESTERDAY & TOMORROW”
              <span className="small-text"> A Film By </span>
              RIVERS CHOWDHRY-CAHEE
              <span className="small-text"> Executive Producer </span>
              MIA CHOWDHRY-CAHEE
              <span className="small-text"> Illustrations By </span>
              TIM MELE
              <span className="small-text"> Composed By </span>
              HANRI THAYYIL
              <span className="small-text"> Written & Directed By </span>
              RIVERS CHOWDHRY-CAHEE
              <span className="small-text"> Director Of Photography </span>
              JEFFERSON CRAWFORD
              <span className="small-text"> Titles By </span>
              ALYSSIA LOU
            </div>
          </div>
          <div className="splash__footer-small-wrapper">
            <div>
              <a href="mailto:rivers@longdaypictures.com">CONTACT</a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
