const Logo = (props) => {
  const { fill } = props;
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 739.13 739.13"
    >
      <path
        fill={fill}
        d="m612.19,159.07v337.43c0,32.14-32.14,31.98-32.14,31.98l-176.75-.84c-32.14-.15-32.14-32.29-32.14-32.29V159.07c0-32.14,32.14-32.14,32.14-32.14h176.75c32.14,0,32.14,32.14,32.14,32.14m80.34-80.34s0-32.14-32.14-32.14H162.29s-32.14,0-32.14,32.14v496.51s0,32.13,32.13,32.23l498.11,1.41s32.14.09,32.14-32.04V78.73Z"
      />
      <path
        fill={fill}
        d="m682.89,739.13H56.24c-23.02,0-36.47-10.88-43.7-20-7.97-10.06-12.54-23.27-12.54-36.24V56.24C0,33.22,10.88,19.77,20,12.54,30.06,4.57,43.27,0,56.24,0h626.65c23.02,0,36.47,10.88,43.7,20,7.97,10.06,12.54,23.27,12.54,36.24v626.65c0,23.02-10.88,36.47-20,43.7-10.06,7.97-23.27,12.54-36.24,12.54ZM56.24,24.1c-11.12,0-32.14,6.72-32.14,32.14v626.65c0,25.42,21.02,32.14,32.14,32.14h626.65c11.12,0,32.14-6.72,32.14-32.14V56.24c0-25.42-21.02-32.14-32.14-32.14H56.24Z"
      />
      <path
        fill={fill}
        d="m81.95,689.32c-32.14,0-32.14-32.14-32.14-32.14V78.73c0-32.14,24.1-32.14,24.1-32.14h13.1c11,0,11,11,11,11v551.38c0,32.14,32.14,32.14,32.14,32.14h0s551.38,0,551.38,0c0,0,11,0,11,11v13.1s0,24.1-32.14,24.1H81.95h0Z"
      />
    </svg>
  );
};

export default Logo;
